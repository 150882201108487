<template>
  <div>
    <b-row>
      <b-col>
        <statistics
          icon="UsersIcon"
          statistic="15"
          statistic-title="Equipo"
          color="primary"
        />
      </b-col>
      <b-col>
        <statistics
          icon="BoxIcon"
          statistic="12"
          statistic-title="Proyectos"
          color="danger"
        />
      </b-col>
      <b-col>
        <statistics
          icon="ActivityIcon"
          statistic="$800,000"
          statistic-title="Ingresos"
          color="warning"
        />
      </b-col>
      <b-col>
        <statistics
          icon="StarIcon"
          statistic="$500,000"
          statistic-title="Utilidades"
          color="success"
        />
      </b-col>
    </b-row>
    <b-card title="Kick start your project 🚀">
      <b-card-text>All the best for your new project.</b-card-text>
      <b-card-text>Please make sure to read our <b-link
        href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"
        target="_blank"
      >
        Template Documentation
      </b-link> to understand where to go from here and how to use our template.</b-card-text>
    </b-card>
    <b-row>
      <b-col>
        <team-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Statistics from '@/@core/components/statistics-cards/StatisticCardHorizontal.vue'
import TeamTable from '@/components/team/TeamTable.vue'

export default {
  components: {
    Statistics,
    TeamTable,
  },
}
</script>

<style>

</style>
