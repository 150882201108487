<template>
  <b-card no-body>
    <b-card-header class="d-flex justify-content-between">
      <b-card-title>Equipo</b-card-title>
      <div class="d-flex">
        <b-input-group class="mr-1">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input placeholder="Buscar..." />
        </b-input-group>
        <b-button
          variant="outline-primary"
        >
          Agregar
        </b-button>
      </div>
    </b-card-header>
    <b-table
      :items="team"
      :fields="teamFields"
    >
      <template #cell(positions)="data">
        <b-badge
          v-for="(position, n) in data.value"
          :key="`position-${n}-${position}`"
          :variant="position.color"
          class="mr-1"
          pill
        >
          {{ position.name }}
        </b-badge>
      </template>
      <template #cell(tools)>
        <feather-icon icon="MoreVerticalIcon" />
      </template>
    </b-table>
    <b-card-footer class="p-1">
      <b-pagination
        v-model="teamPagination.page"
        class="mb-0"
        :total-rows="teamPagination.total_objects"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      teamFields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'git',
          label: 'Git',
        },
        {
          key: 'positions',
          label: 'Posición',
        },
        {
          key: 'tools',
          label: '',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('team', ['team', 'teamPagination']),
  },
}
</script>

<style lang="scss" scoped>

</style>
